<template>
  <a :href="params.link" :target="params.blank ? '_blank' : '_self'">
    <div class="link-box">
      <div class="link-box__wrapper">
        <Row justify="between">
          <Column>
            <span class="link-box__title">
              <slot name="link-box-title"/>
            </span>
            <span class="link-box__description">
              <slot name="link-box-description"/>
            </span>
          </Column>
          <Column>
            <div class="link-box__round">
              <slot name="link-box-icon"/>
            </div>
          </Column>
        </Row>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  name: 'LinkBox',
  props: {
    params: {},
  },
};
</script>
